import TagManager from "react-gtm-module";

export const sendAnalytics = (eventName: string, customData = {}): void => {
  if (typeof window === "undefined") {
    return;
  }

  const dataLayer = {
    ...customData,
    event: eventName
  };

  TagManager.dataLayer({ dataLayer });
};
