type Props = {
  supportUrl: string;
  supportLabel: string;
  supportOnClick: () => void;
  legalLabel: string;
  locale: string;
  jpPaymentCheckLabel: string;
  jpCommercialTransactionsLabel: string;
  jpRefundPolicyLabel: string;
  jpRefundPolicyUrl: string;
};

const Footer = (props: Props): JSX.Element => {
  if (props.locale === "ja-JP") {
    return (
      <div className="web-pay-footer locale-jp">
        <footer id="riotbar-footer-container" />
        <div className="footer-links">
          <a href={props.jpRefundPolicyUrl} target="_blank" rel="noreferrer" className="footer-link">
            {props.jpRefundPolicyLabel}
          </a>
        </div>
      </div>
    );
  }
  return (
    <div className="web-pay-footer">
      <div className="footer-links">
        <a
          href={props.supportUrl}
          target="_blank"
          rel="noreferrer"
          data-interaction-id="webpay-footer_support"
          onClick={props.supportOnClick}
          className="footer-link"
        >
          {props.supportLabel}
        </a>
      </div>
      <footer id="riotbar-footer-container" />
    </div>
  );
};

export default Footer;
