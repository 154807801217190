import { MouseEventHandler } from "react";

/**
 * A styled backbutton that will redirect users back in steps, or in history if no more steps
 */
export const BackButton = ({ onClick }: { onClick: MouseEventHandler<HTMLDivElement> }): JSX.Element => {
  return (
    <div className="back-btn-container" onClick={onClick}>
      <div className="back-btn">
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0 18C0 8.05888 8.05888 0 18 0H30C39.9411 0 48 8.05888 48 18V30C48 39.9411 39.9411 48 30 48H18C8.05888 48 0 39.9411 0 30V18Z"
            fill="#8A8A8A"
            fill-opacity="0.1"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.0402 22.7523L25.0974 14.7605L23.3225 13L13.1176 23.122C12.6275 23.6082 12.6275 24.3964 13.1176 24.8826L23.3225 35.0046L25.0974 33.244L17.0403 25.2523L35.2513 25.2523V22.7523L17.0402 22.7523Z"
            fill="#F0F0F0"
          />
        </svg>
      </div>
    </div>
  );
};
