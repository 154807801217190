import { FormattedMessage, FormattedNumber } from "react-intl";
import { useSelector } from "react-redux";

import { getTaxDisclaimer, getShowVat, getPurchaseCurrency } from "@riotgames/lib-riotpay-state/modules/paymentOptions";
import {
  getLoadingState,
  getRealCurrencyAmount,
  getRealCurrencyCode,
  getSalesTax,
  getVirtualCurrencyAmount,
  getVirtualCurrencyWalletBalance,
  getVirtualCurrencyAmountPrice,
  getOrderDetails,
  LOADING_STATE_FAILED as FAILED,
  LOADING_STATE_LOADING as LOADING,
  LOADING_STATE_UNATTEMPTED as UNATTEMPTED
} from "@riotgames/lib-riotpay-state/modules/purchaseContext";
import { Loading } from "@riotgames/riotpay-components";

function OrderSummary() {
  const loadingState = useSelector(getLoadingState);
  const orderDetails = useSelector(getOrderDetails);
  const realCurrencyAmount = useSelector(getRealCurrencyAmount);
  const realCurrencyCode = useSelector(getRealCurrencyCode);
  const salesTax = useSelector(getSalesTax);
  const showTaxDisclaimer = useSelector(getTaxDisclaimer);
  const showVatDisclaimer = useSelector(getShowVat);
  const vcPricePointAmount = useSelector(getVirtualCurrencyAmount);
  const vcTotalAmount = useSelector(getVirtualCurrencyAmountPrice);
  const vcBalance = useSelector(getVirtualCurrencyWalletBalance);
  const vcCode = useSelector(getPurchaseCurrency);

  const SVG_VirtualCurrencyGray = `/images/svgs/ico-${vcCode}-gray.svg`;
  const SVG_VirtualCurrencyBundle = `/images/svgs/${vcCode}-bundle.svg`;
  const PNG_VirtualCurrencyLor = `/images/svgs/ico-coins-raster.png`;
  const PNG_VirtualCurrencyBundleLor = `/images/svgs/coins-bundle.png`;

  if (loadingState === LOADING || loadingState === FAILED || loadingState === UNATTEMPTED) {
    return <Loading className="order-summary" />;
  }

  const { name, thumbnailUrl, affiliateName } = orderDetails;

  const taxAmount = salesTax?.saleTaxInCents / 100 || 0;
  const vcNewBalance = vcBalance + vcPricePointAmount - vcTotalAmount;

  return (
    <div className="order-summary">
      <h4>
        <FormattedMessage id="order_summary" defaultMessage="Order Summary" />
      </h4>
      <table className="order-summary-details">
        <thead>
          {vcBalance !== undefined && (
            <tr id="order-summary-vc-balance">
              <td>
                <FormattedMessage id="current_balance" defaultMessage="Current Balance" />
              </td>
              <td>
                <img
                  id="ico"
                  src={vcCode === "coins" ? PNG_VirtualCurrencyLor : SVG_VirtualCurrencyGray}
                  alt={vcCode}
                />
                {vcBalance} <FormattedMessage id={`vc_code_${vcCode}`} defaultMessage={vcCode} />
              </td>
            </tr>
          )}
        </thead>
        <tbody>
          <tr id="order-summary-buying">
            <td>
              <FormattedMessage id="buying" defaultMessage="Buying" />
            </td>
            <td>&nbsp;</td>
          </tr>
          <tr id="order-summary-vc-buying">
            <td>
              <img src={vcCode === "coins" ? PNG_VirtualCurrencyBundleLor : SVG_VirtualCurrencyBundle} alt={vcCode} />
              <FormattedMessage
                id="vc_bundle"
                defaultMessage="{amount} {code} Bundle"
                values={{
                  amount: vcPricePointAmount,
                  code: <FormattedMessage id={`vc_code_${vcCode}`} defaultMessage={vcCode} />
                }}
              />
            </td>
            <td>
              +&nbsp;
              <img id="ico" src={vcCode === "coins" ? PNG_VirtualCurrencyLor : SVG_VirtualCurrencyGray} alt={vcCode} />
              {vcPricePointAmount} <FormattedMessage id={`vc_code_${vcCode}`} defaultMessage={vcCode} />
            </td>
          </tr>
          <tr id="order-summary-product-details">
            <td>
              {thumbnailUrl && name && (
                <>
                  <img src={thumbnailUrl} alt={name} />
                  {name}
                </>
              )}
            </td>
            <td>
              {vcTotalAmount !== undefined && !isNaN(vcTotalAmount) && vcTotalAmount > 0 && (
                <>
                  -&nbsp;
                  <img
                    id="ico"
                    src={vcCode === "coins" ? PNG_VirtualCurrencyLor : SVG_VirtualCurrencyGray}
                    alt={vcCode}
                  />
                  {vcTotalAmount} <FormattedMessage id={`vc_code_${vcCode}`} defaultMessage={vcCode} />
                </>
              )}
            </td>
          </tr>
        </tbody>
        <tfoot>
          {vcBalance !== undefined && vcNewBalance !== undefined && !isNaN(vcNewBalance) && (
            <tr id="order-summary-new-balance">
              <td>
                <FormattedMessage id="new_balance" defaultMessage="New Balance" />
              </td>
              <td>
                <img
                  id="ico"
                  src={vcCode === "coins" ? PNG_VirtualCurrencyLor : SVG_VirtualCurrencyGray}
                  alt={vcCode}
                />
                {vcNewBalance} <FormattedMessage id={`vc_code_${vcCode}`} defaultMessage={vcCode} />
              </td>
            </tr>
          )}
          {(taxAmount > 0 || (showTaxDisclaimer && !showVatDisclaimer)) && (
            <tr id="order-summary-price-tax">
              <td>
                <FormattedMessage id="tax" defaultMessage="Tax" />
              </td>
              <td>
                {taxAmount !== undefined && realCurrencyCode !== undefined && (
                  <FormattedNumber value={taxAmount} currency={realCurrencyCode} style="currency" />
                )}
              </td>
            </tr>
          )}
          <tr id="order-summary-price-total">
            <td>
              <FormattedMessage id="total" defaultMessage="Total" />
              &nbsp;
              <span id="order-summary-price-total-currency">{realCurrencyCode}</span>
            </td>
            <td>
              {realCurrencyAmount + taxAmount !== undefined && realCurrencyCode !== undefined && (
                <FormattedNumber value={realCurrencyAmount + taxAmount} currency={realCurrencyCode} style="currency" />
              )}
            </td>
          </tr>
        </tfoot>
      </table>
      {showTaxDisclaimer && (
        <span className="order-summary-disclaimer">
          <FormattedMessage
            id="Applicable_taxes_calculated_at_checkout"
            defaultMessage="Applicable taxes calculated at checkout"
          />
        </span>
      )}
      {showVatDisclaimer && (
        <span className="order-summary-disclaimer">
          <FormattedMessage id="prices_include_VAT" defaultMessage="All prices are inclusive of VAT if applicable" />
        </span>
      )}
      {!!affiliateName && (
        <span className="order-summary-affiliate-indicator">
          <FormattedMessage
            id="affiliate_indicator"
            defaultMessage={`You’ll be supporting ${affiliateName} with your purchase.`}
            values={{
              affiliateName
            }}
          />
        </span>
      )}
    </div>
  );
}

export default OrderSummary;
