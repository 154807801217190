import React from "react";

type Props = React.PropsWithChildren<{
  order: number;
  title: string;
}>;

const PaymentOptionsGroup: React.FC<Props> = ({ children, order, title }) => {
  return (
    <div className="pay-option-group">
      {order > 0 ? <div className="pay-options-divider" /> : undefined}
      <h3>{title}</h3>
      {children}
    </div>
  );
};

export default PaymentOptionsGroup;
