import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setErrorReason } from "@riotgames/lib-riotpay-state/modules/error";

const CUSTOM_ERROR_KEY = "customerror";

function EmbeddedPurchase({ checkoutUrl }) {
  const dispatch = useDispatch();
  const [sectionHeaderText, setSectionHeaderText] = useState("");

  const messageHandler = ({ data, origin }) => {
    if (!origin?.match(new RegExp(process.env.REACT_APP_RIOTPAY_HOST_REGEX))) {
      return;
    }

    if (data.tag === "section-header-text") {
      setSectionHeaderText(data.text);
    }

    if (data.tag === "redirect-client") {
      const redirectUrl = new URL(data.url);

      if (redirectUrl.searchParams.get(CUSTOM_ERROR_KEY)) {
        dispatch(setErrorReason(redirectUrl.searchParams.get(CUSTOM_ERROR_KEY)));
        return;
      }

      window.location.href = redirectUrl.toString();
    }
  };

  useEffect(() => {
    window.addEventListener("message", messageHandler, false);
    window.scrollTo(0, 0);

    return () => {
      window.removeEventListener("message", messageHandler);
    };
  });

  return (
    <div className="embedded-purchase">
      <h2>{sectionHeaderText}</h2>
      <iframe name="checkout-start-container" id="checkout-start-container" title="Checkout Start" src={checkoutUrl} />
    </div>
  );
}

export default EmbeddedPurchase;
