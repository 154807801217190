import React from "react";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import { iconResolver } from "@riotgames/riotpay-utils";

import iconMap from "../iconMap.json";

export interface PaymentOptionCardProps {
  paymentMethodId: string;
  disabled: boolean;
  onClick: () => void;
  selected: boolean;
}

const PaymentOptionCard: React.FC<PaymentOptionCardProps> = ({ paymentMethodId, disabled, onClick, selected }) => {
  return (
    <div
      className={clsx("pay-option-card", selected && !disabled && "active", disabled && "disabled")}
      data-interaction-id={`webpay-psp_${paymentMethodId}`}
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
    >
      <img
        className="pay-option-img"
        src={iconResolver(iconMap, { paymentMethod: paymentMethodId })}
        alt={paymentMethodId}
      />
      <label>
        <FormattedMessage id={`psp-name-${paymentMethodId}`} defaultMessage={paymentMethodId} />
      </label>
    </div>
  );
};

export default PaymentOptionCard;
