import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { getPurchaseCurrency } from "@riotgames/lib-riotpay-state/modules/paymentOptions";
import {
  getLoadingState,
  getVirtualCurrencyWalletBalance,
  getVirtualCurrencyAmountPrice,
  getOrderDetails,
  LOADING_STATE_FAILED as FAILED,
  LOADING_STATE_LOADING as LOADING,
  LOADING_STATE_UNATTEMPTED as UNATTEMPTED
} from "@riotgames/lib-riotpay-state/modules/purchaseContext";
import { Loading } from "@riotgames/riotpay-components";

function CartAndBalance() {
  const loadingState = useSelector(getLoadingState);
  const orderDetails = useSelector(getOrderDetails);
  const vcTotalAmount = useSelector(getVirtualCurrencyAmountPrice);
  const vcBalance = useSelector(getVirtualCurrencyWalletBalance);
  const vcCode = useSelector(getPurchaseCurrency);

  const SVG_VirtualCurrencyGray = `/images/svgs/ico-${vcCode}-gray.svg`;
  const SVG_VirtualCurrencyPrimary = `/images/svgs/ico-${vcCode}-primary.svg`;
  const PNG_VirtualCurrencyLor = `/images/svgs/ico-coins-raster.png`;

  if (loadingState === LOADING || loadingState === FAILED || loadingState === UNATTEMPTED) {
    return <Loading className="order-summary" />;
  }

  const { name, type, thumbnailUrl, affiliateName } = orderDetails;

  const vcNeeded = vcTotalAmount - (vcBalance || 0);

  return (
    <div className="cart-summary">
      {[name, type, thumbnailUrl].some((el) => el) && ( // checks if element is undefined
        <>
          <h4>
            <FormattedMessage id="your_cart" defaultMessage="Your Cart" />
          </h4>
          <table className="cart-summary-product-details">
            <tbody>
              <tr>
                <td>
                  {thumbnailUrl && name && (
                    <>
                      <img src={thumbnailUrl} alt={name} />
                    </>
                  )}
                  <div className="cart-summary-product-info">
                    <label id="cart-summary-product-description">{name}</label>
                    {type && <label id="cart-summary-product-type">{type}</label>}
                  </div>
                </td>
                <td>
                  <img
                    id="ico"
                    src={vcCode === "coins" ? PNG_VirtualCurrencyLor : SVG_VirtualCurrencyGray}
                    alt={vcCode}
                  />
                  {vcTotalAmount} <FormattedMessage id={`vc_code_${vcCode}`} defaultMessage={vcCode} />
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
      <h4>
        <FormattedMessage
          id="vc_balance"
          defaultMessage="{code} Balance"
          values={{
            code: <FormattedMessage id={`vc_code_${vcCode}`} defaultMessage={vcCode} />
          }}
        />
      </h4>
      <table className="cart-summary-details">
        <tbody>
          <tr id="cart-summary-vc-balance">
            <td>
              <FormattedMessage id="current_balance" defaultMessage="Current Balance" />
            </td>
            <td>
              <img id="ico" src={vcCode === "coins" ? PNG_VirtualCurrencyLor : SVG_VirtualCurrencyGray} alt={vcCode} />
              {vcBalance || 0} <FormattedMessage id={`vc_code_${vcCode}`} defaultMessage={vcCode} />
            </td>
          </tr>
          {vcNeeded !== undefined && !isNaN(vcNeeded) && vcNeeded > 0 && (
            <tr id="cart-summary-vc-needed" className={vcCode}>
              <td>
                <FormattedMessage
                  id="vc_needed"
                  defaultMessage="{code} Needed"
                  values={{
                    code: <FormattedMessage id={`vc_code_${vcCode}`} defaultMessage={vcCode} />
                  }}
                />
              </td>
              <td>
                <img
                  id="ico"
                  src={vcCode === "coins" ? PNG_VirtualCurrencyLor : SVG_VirtualCurrencyPrimary}
                  alt={vcCode}
                />
                {vcNeeded} <FormattedMessage id={`vc_code_${vcCode}`} defaultMessage={vcCode} />
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {!!affiliateName && (
        <span className="cart-summary-affiliate-indicator">
          <FormattedMessage
            id="affiliate_indicator"
            defaultMessage={`You’ll be supporting ${affiliateName} with your purchase.`}
            values={{
              affiliateName
            }}
          />
        </span>
      )}
    </div>
  );
}

export default CartAndBalance;
