import { CONFIRM_PP_SUCCESS, CONFIRM_DP_SUCCESS, CONFIRM_PREPAID_PP_SUCCESS } from "./paymentOptions";

const INIT_SESSION_TOKEN = "pmc-client/session/INIT_TOKEN";
const INIT_SESSION_PREPAIDMODE = "pmc-client/session/INIT_PREPAIDMODE";
const INIT_SESSION_INSTANCE = "pmc-client/session/INIT_INSTANCE";
const INIT_GLOBAL_CSS_THEME = "pmc-client/session/INIT_GLOBAL_CSS_THEME";
const INIT_STORE_CODE = "pmc-client/session/INIT_STORE_CODE";
const INIT_SESSION_ISKOREA = "pmc-client/session/INIT_ISKOREA";
const INIT_SESSION_API_V2 = "pmc-client/session/INIT_SESSION_API_V2";
const INIT_SESSION_CURRENT_PURCHASE_ID = "pmc-client/session/INIT_SESSION_CURRENT_PURCHASE_ID";

// This is shared between paymentOptions reducer and this reducer
export const START_NEW_PURCHASE = "pmc-client/session/START_NEW_PURCHASE";

export function initSessionToken(sessionToken) {
  return {
    type: INIT_SESSION_TOKEN,
    sessionToken
  };
}

export function initSessionPrepaidMode(prepaidMode) {
  return {
    type: INIT_SESSION_PREPAIDMODE,
    prepaidMode
  };
}

export function initSessionInstance(instance) {
  return {
    type: INIT_SESSION_INSTANCE,
    instance
  };
}

export function initGlobalCssTheme(cssTheme) {
  return {
    type: INIT_GLOBAL_CSS_THEME,
    cssTheme
  };
}

export function initSessionStoreCode(storeCode) {
  return {
    type: INIT_STORE_CODE,
    storeCode
  };
}

export function initSessionIsKorea(isKorea) {
  return {
    type: INIT_SESSION_ISKOREA,
    isKorea
  };
}

export function initSessionUseApiV2(useApiV2) {
  return {
    type: INIT_SESSION_API_V2,
    useApiV2
  };
}

export function initSessionCurrentPurchaseId(currentPurchaseId) {
  return {
    type: INIT_SESSION_CURRENT_PURCHASE_ID,
    currentPurchaseId
  };
}

// We display a prepaid purchase success dialog based on the value of "prepaidSuccess" and we cannot start
// a new purchase until we reset this state, this is needed for purchases 2.0 where we re-use the existing session
// We also need to reset the current purchase ID to null
export const startNewPurchase = () => ({
  type: START_NEW_PURCHASE
});

export const initialState = {
  token: "",
  prepaidMode: false,
  instance: "",
  isKorea: false,
  useApiV2: false,
  currentPurchaseId: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CONFIRM_PREPAID_PP_SUCCESS:
    case CONFIRM_PP_SUCCESS:
    case CONFIRM_DP_SUCCESS: {
      const currentPurchaseId =
        action && action.response && action.response.purchaseId ? action.response.purchaseId : null;

      // We need to store current purchase ID in the session storage
      // because we will redirect to a different web site
      sessionStorage.setItem("currentPurchaseId", currentPurchaseId);

      return {
        ...state,
        currentPurchaseId
      };
    }

    case INIT_SESSION_TOKEN: {
      return {
        ...state,
        token: action.sessionToken
      };
    }
    case INIT_SESSION_PREPAIDMODE: {
      return {
        ...state,
        prepaidMode: action.prepaidMode
      };
    }
    case INIT_SESSION_INSTANCE: {
      return {
        ...state,
        instance: action.instance
      };
    }
    case INIT_GLOBAL_CSS_THEME: {
      return {
        ...state,
        cssTheme: action.cssTheme
      };
    }
    case INIT_STORE_CODE: {
      return {
        ...state,
        storeCode: action.storeCode
      };
    }
    case INIT_SESSION_ISKOREA: {
      return {
        ...state,
        isKorea: action.isKorea
      };
    }
    case INIT_SESSION_API_V2: {
      return {
        ...state,
        useApiV2: action.useApiV2
      };
    }
    case START_NEW_PURCHASE: {
      // We need to store current purchase ID in the session storage
      // because we will regirect to a different web site
      sessionStorage.setItem("currentPurchaseId", null);
      return {
        ...state,
        currentPurchaseId: null
      };
    }
    case INIT_SESSION_CURRENT_PURCHASE_ID: {
      // We need to store current purchase ID in the session storage
      // because we will regirect to a different web site
      sessionStorage.setItem("currentPurchaseId", action.currentPurchaseId);
      return {
        ...state,
        currentPurchaseId: action.currentPurchaseId
      };
    }
    default:
      return state;
  }
}

/* Selectors */
export const getSessionToken = (state) => state.session.token;
export const getSessionPrepaidMode = (state) => state.session.prepaidMode;
export const getSessionInstance = (state) => state.session.instance;
export const getSessionIsKorea = (state) => state.session.isKorea;
export const getSessionUseApiV2 = (state) => state.session.useApiV2;
export const getSessionCurrentPurchaseId = (state) => state.session.currentPurchaseId;
export const getSessionStoreCode = (state) => state.session.storeCode;
export const getGlobalCssTheme = (state) => state.session.cssTheme;
