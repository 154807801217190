import React from "react";

type Props = {
  children?: React.ReactNode;
};

const PurchaseActions: React.FC<Props> = ({ children }) => {
  return <div id="purchase-actions">{children}</div>;
};

export default PurchaseActions;
