import React, { useEffect } from "react";
import Modal from "react-modal";

const App: React.FC<React.ReactNode> = ({ children }) => {
  useEffect(() => {
    Modal.setAppElement(".app-main");
  });

  return <div className="app-main">{children}</div>;
};

export default App;
