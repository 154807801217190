import { LOAD_SUCCESS as PAYMENT_OPTIONS_LOAD } from "./paymentOptions";

const INIT_AUTH = "pmc-client/auth/INIT";

// First token is the rso, second is the PMC session token.
export function initAuth(token, sessionToken) {
  return {
    type: INIT_AUTH,
    token,
    sessionToken
  };
}

const initialState = {
  token: "",
  country: {}
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case INIT_AUTH: {
      return {
        ...state,
        token: action.token,
        sessionToken: action.sessionToken
      };
    }
    case PAYMENT_OPTIONS_LOAD:
      return {
        token: state.token,
        sessionToken: state.sessionToken,
        country: action.response && action.response.currentCountry ? action.response.currentCountry : {}
      };
    default:
      return state;
  }
}

/* Selectors */
export const getAuthToken = (state) => state.user.token;
export const getSessionToken = (state) => state.user.sessionToken;
export const getCurrentCountry = (state) => state.user.country;
