import { combineReducers } from "redux";

export const defaultReducers = [
  "user",
  "session",
  "paymentOptions",
  "checkout",
  "error",
  "purchaseConsent",
  "purchaseContext"
];

export const buildReducers = (reducerNames = defaultReducers) => {
  const reducersToUse = reducerNames.reduce((acc, reducerName) => {
    acc[reducerName] = require(`./${reducerName}`).default;
    return acc;
  }, {});

  return combineReducers(reducersToUse);
};
