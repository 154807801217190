import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import {
  getPaymentOptionsLoadingState,
  getPaymentOptionsView,
  getSavedPaymentMethods,
  LOADING_STATE_LOADING as LOADING,
  LOADING_STATE_FAILED as FAILED
} from "@riotgames/lib-riotpay-state/modules/paymentOptions";
import { getSessionStoreCode } from "@riotgames/lib-riotpay-state/modules/session";
import { Loading } from "@riotgames/riotpay-components";

import PaymentOptionCard from "../components/PaymentOptionCard";
import PaymentOptionsGroup from "../components/PaymentOptionsGroup";
import SavedPaymentMethodCard from "../components/SavedPaymentMethodCard";
import { useEffect, useRef } from "react";
import { getPricePointId } from "@riotgames/lib-riotpay-state/modules/purchaseContext";
import { useHistory } from "react-router-dom";

function PaymentOptions({ disabled, loading, selectPaymentOptionListener, selectedPaymentOption }) {
  const intl = useIntl();
  const history = useHistory();

  const loaded = useRef(false);
  const loadingState = useSelector(getPaymentOptionsLoadingState);
  const paymentOptions = useSelector(getPaymentOptionsView);
  const savedPaymentMethods = useSelector(getSavedPaymentMethods);
  const selectedPricePointId = useSelector(getPricePointId);
  const storeCode = useSelector(getSessionStoreCode);

  useEffect(() => {
    if (!loading && !loaded.current) {
      loaded.current = true;
      if (!selectedPricePointId) {
        history.push({
          pathname: "/price-points"
        });
      }
    }
  });

  if (loadingState === LOADING || loadingState === FAILED || loading) {
    return <Loading className="pay-options" />;
  }

  // saved payment method is not functioning, hiding it until we fix the issue
  // const savedPaymentMethodsEnabled = savedPaymentMethods?.length > 0;
  const savedPaymentMethodsEnabled = false;

  const paymentOptionsLabel = savedPaymentMethodsEnabled
    ? intl.formatMessage({
        id: "other_payment_option",
        defaultMessage: "Other Payment Options"
      })
    : intl.formatMessage({
        id: "payment_options",
        defaultMessage: "Payment Options"
      });

  return (
    <div className={`pay-options ${storeCode}`}>
      <div className="pay-options-group-title">
        <h2>
          <FormattedMessage id="checkout" defaultMessage="Checkout" />
        </h2>
      </div>
      {savedPaymentMethodsEnabled && (
        <PaymentOptionsGroup
          title={intl.formatMessage({
            id: "saved_payment_methods",
            defaultMessage: "Saved Payment Methods"
          })}
          order={0}
        >
          {savedPaymentMethods.map((option) => (
            <SavedPaymentMethodCard
              key={option.uniquePaymentMethodId}
              paymentMethodId={option.paymentMethodId}
              disabled={disabled}
              onClick={() => selectPaymentOptionListener(option)}
              selected={selectedPaymentOption.token === option.token}
              last4Digits={option.last4Digits}
              cardBrand={option.cardBrand}
            />
          ))}
        </PaymentOptionsGroup>
      )}
      <PaymentOptionsGroup title={paymentOptionsLabel} order={savedPaymentMethodsEnabled ? 1 : 0}>
        {paymentOptions.map((option) => (
          <PaymentOptionCard
            key={option.uniquePaymentMethodId}
            paymentMethodId={option.paymentMethodId}
            disabled={disabled}
            onClick={() => selectPaymentOptionListener(option)}
            selected={selectedPaymentOption.uniquePaymentMethodId === option.uniquePaymentMethodId}
          />
        ))}
      </PaymentOptionsGroup>
    </div>
  );
}

export default PaymentOptions;
