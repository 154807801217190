import { CALL_API } from "../middleware/api";
import { CONFIRM_PREPAID_PP_SUCCESS } from "./paymentOptions";

export const LOAD = "pmc-client/checkout/LOAD";
export const LOAD_SUCCESS = "pmc-client/checkout/LOAD_SUCCESS";
export const LOAD_FAIL = "pmc-client/checkout/LOAD_FAIL";
export const LOAD_RETRY_SUCCESS = "pmc-client/checkout/LOAD_RETRY_SUCCESS";

/* Selector */
export const getCheckout = (state) => state.checkout;

export const loadPricePointPurchaseCheckout = (sessionToken, retry = false) => {
  const successAction = retry ? LOAD_RETRY_SUCCESS : LOAD_SUCCESS;

  return {
    [CALL_API]: {
      types: [LOAD, successAction, LOAD_FAIL],
      endpoint: `riotpay/pmc/v1/price-point-purchases/${sessionToken}`
    }
  };
};

export const loadPricePointPurchaseCheckoutV2 = (purchaseId, retry = false) => {
  const successAction = retry ? LOAD_RETRY_SUCCESS : LOAD_SUCCESS;

  return {
    [CALL_API]: {
      types: [LOAD, successAction, LOAD_FAIL],
      endpoint: `riotpay/pmc/v2/price-point-purchases/${purchaseId}`
    }
  };
};

export const loadDirectPurchaseCheckout = (sessionToken, retry = false) => {
  const successAction = retry ? LOAD_RETRY_SUCCESS : LOAD_SUCCESS;

  return {
    [CALL_API]: {
      types: [LOAD, successAction, LOAD_FAIL],
      endpoint: `riotpay/pmc/v1/direct-purchases/${sessionToken}`
    }
  };
};

const initialState = {
  loaded: false,
  loading: false,
  loadRetried: false
};

export default function reducer(state = initialState, { type, response }) {
  switch (type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        loadRetried: false,
        ...response
      };
    case LOAD_RETRY_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        loadRetried: true,
        ...response
      };
    case CONFIRM_PREPAID_PP_SUCCESS:
      return {
        loading: false,
        loaded: true,
        ...response
      };
    default:
      return state;
  }
}

export const getCheckoutLoadingState = (state) => state.checkout.loading;

export const getCheckoutLoadRetryState = (state) => !!state.checkout.loadRetried;
