function ConfirmPurchaseButton({ btnText, disabled, onClick, showImage, storeCode }) {
  return (
    <>
      <button
        id="btn_confirm_purchase"
        className={`btn-primary ${storeCode}`}
        disabled={disabled}
        data-interaction-id="webpay-psp_confirm"
        onClick={onClick}
      >
        <span>{btnText}</span>
        {showImage && <div className={`action-btn-img${disabled ? " disabled" : ""}`} />}
      </button>
    </>
  );
}

export default ConfirmPurchaseButton;
