import { matchPath, Route } from "react-router-dom";

const DCON_REDIRECT_PATHS = [
  "/:locale/:game/:content/:status",
  "/:locale/:game/:content",
  "/account/name-change-token"
];

export const shouldRedirectToDCON = (failRedirectUrl) => {
  const currentPath = window.location.pathname;
  const match = matchPath(currentPath, {
    path: getRedirectPaths(failRedirectUrl),
    exact: true
  });
  return match !== null;
};

export const registerRedirectRoute = (failRedirectUrl) => {
  if (shouldRedirectToDCON(failRedirectUrl)) {
    return (
      <Route
        path={getRedirectPaths(failRedirectUrl)}
        component={() => {
          window.location.href =
            process.env.REACT_APP_DCON_REDIRECT_BASE_URL +
            window.location.pathname +
            window.location.search +
            window.location.hash;
          return null;
        }}
      />
    );
  }

  return null;
};

const getRedirectPaths = (failRedirectUrl) => {
  const paths = [...DCON_REDIRECT_PATHS];
  const redirectUrl = failRedirectUrl ?? sessionStorage.getItem("failRedirectUrl");
  try {
    const failRedirectPath = new URL(redirectUrl).pathname;
    paths.push(failRedirectPath);
  } catch (error) {
    // do nothing, return paths
  }
  return paths;
};
