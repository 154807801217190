import clsx from "clsx";
import queryString from "query-string";
import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Route, Redirect, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { EuRightToCancel } from "@riotgames/riotpay-components";
import { DEFAULT_LOCALE } from "@riotgames/riotpay-l18n";
import {
  getAccountTag,
  getInGameName,
  getPuuid,
  getPricePointId,
  getLoadingState as getPurchaseContextLoadingState,
  loadPurchaseContextIfNeeded as loadPurchaseContext,
  LOADING_STATE_UNATTEMPTED as PURCHASE_CONTEXT_UNATTEMPTED,
  LOADING_STATE_SUCCESS as PURCHASE_CONTEXT_LOADED,
  setPricePoint,
  getVirtualCurrencyWalletBalance,
  getVirtualCurrencyAmountPrice,
  getVirtualCurrencyAmount,
  getVirtualCurrencyCode,
  getFailRedirectUrl
} from "@riotgames/lib-riotpay-state/modules/purchaseContext";
import {
  clearCheckoutUrl,
  confirmPricePointPurchaseV2 as confirmPricePointPurchase,
  getCheckoutUrl,
  getConfirmPurchaseLoadingState,
  getShowEuRightToCancel,
  getPaymentOptionsLoadingState,
  initSessionVirtualCurreny,
  loadPaymentOptionsV2 as loadPaymentOptions,
  LOADING_STATE_SUCCESS as PAYMENT_OPTIONS_LOADED,
  getAllPricePoints
} from "@riotgames/lib-riotpay-state/modules/paymentOptions";
import {
  getSessionStoreCode,
  initSessionToken,
  initSessionInstance,
  initGlobalCssTheme,
  initSessionStoreCode,
  initSessionCurrentPurchaseId
} from "@riotgames/lib-riotpay-state/modules/session";
import { applyGlobalCssTheme } from "@riotgames/riotpay-utils";
import RiotBar from "@riotgames/riotbar-react";

import OrderSummary from "./OrderSummary";
import PaymentOptions from "./PaymentOptions";
import ConfirmPurchaseButton from "../components/ConfirmPurchaseButton";
import Footer from "../components/Footer";
import PurchaseActions from "../components/PurchaseActions";
import StartPurchase from "../components/StartPurchase";

import { sendAnalytics } from "../utils/analytics";
import { registerRedirectRoute, shouldRedirectToDCON } from "../utils/dconRedirect";
import PricePoints from "./PricePoints";
import CartAndBalance from "./CartAndBalance";
import { BackButton } from "../components/BackButton";

const DEFAULT_STORE_CODE = "webpay";
const DEFAULT_THEME = "webpay";
const DEFAULT_INSTANCE = "rgi";

function WebPayPurchase() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const intl = useIntl();

  const defaultState = useMemo(
    () => ({
      canStartPurchase: false,
      actionBtnText: intl.formatMessage({
        id: "continue_to_payment",
        defaultMessage: "Continue to Payment"
      }),
      actionBtnDisabled: true,
      actionCheckboxText: "",
      actionCheckboxVisible: false,
      actionCheckboxChecked: false,
      actionImgVisible: false,
      actionDisclaimerText: "",
      selectedPaymentOption: {},
      riotBarReady: false
    }),
    [intl]
  );
  const [state, _setState] = useState(defaultState);
  const setState = useCallback(
    (newState) => {
      _setState({ ...state, ...newState });
    },
    [state, _setState]
  );

  // Used by child component
  const [euCheckboxChecked, setEuCheckboxChecked] = useState(false);
  const [actionBtnDisabledGuide, setActionBtnDisabledGuide] = useState(false);

  const nextStepRef = useRef(() => null);
  const setNextStepFunc = (ns, label) => {
    nextStepRef.current = ns;
    if (label) {
      setState({ actionBtnText: label });
    }
  };

  const setValidPurchase = (eventData, eventName) => {
    if (eventName === "onValid" && eventData.valid) {
      setState({ actionBtnDisabled: false });
    } else {
      !state.actionBtnDisabled && setState({ actionBtnDisabled: true });
    }
  };

  const purchaseHasStartedRef = useRef(false);

  // Redux State
  const storeCode = useSelector(getSessionStoreCode);
  const accountTag = useSelector(getAccountTag);
  const checkoutUrl = useSelector(getCheckoutUrl);
  const pricePointId = useSelector(getPricePointId);
  const pricePoints = useSelector(getAllPricePoints);
  const inGameName = useSelector(getInGameName);
  const puuid = useSelector(getPuuid);
  const failRedirectUrl = useSelector(getFailRedirectUrl);

  const confirmPurchaseLoadingState = useSelector(getConfirmPurchaseLoadingState);
  const paymentOptionsLoadingState = useSelector(getPaymentOptionsLoadingState);
  const purchaseContextLoadingState = useSelector(getPurchaseContextLoadingState);
  const showEuRightToCancel = useSelector(getShowEuRightToCancel);

  const vcBalance = useSelector(getVirtualCurrencyWalletBalance);
  const vcPrice = useSelector(getVirtualCurrencyAmountPrice);
  const vcAmount = useSelector(getVirtualCurrencyAmount);
  const vcNeeded = vcPrice - vcBalance;
  const vcCode = useSelector(getVirtualCurrencyCode);

  const isPaymentOptions = location.pathname === "/payment-options";
  const isPricePoints = location.pathname === "/price-points";
  const isPurchasePage = location.pathname === "/purchase";

  const consumeQueryParams = useCallback(() => {
    const query = queryString.parse(location.search);

    const locale = query.locale || sessionStorage.getItem("locale") || DEFAULT_LOCALE;
    sessionStorage.setItem("locale", locale);

    const sessionToken = query.s || sessionStorage.getItem("sessionToken");
    dispatch(initSessionToken(sessionToken));
    sessionStorage.setItem("sessionToken", sessionToken);

    const virtualCurrencyCode = (query.vc || sessionStorage.getItem("virtualCurrencyCode"))?.toLowerCase();
    dispatch(initSessionVirtualCurreny(virtualCurrencyCode));
    sessionStorage.setItem("virtualCurrencyCode", virtualCurrencyCode);

    const instance = query.i || sessionStorage.getItem("instance") || DEFAULT_INSTANCE;
    dispatch(initSessionInstance(instance));
    sessionStorage.setItem("instance", instance);

    const storeCode = query.sc || sessionStorage.getItem("storeCode") || DEFAULT_STORE_CODE;
    dispatch(initSessionStoreCode(storeCode));
    sessionStorage.setItem("storeCode", storeCode);

    const cssTheme = query.t || sessionStorage.getItem("cssTheme") || DEFAULT_THEME;
    dispatch(initGlobalCssTheme(cssTheme));
    sessionStorage.setItem("cssTheme", cssTheme);
    applyGlobalCssTheme(cssTheme);

    dispatch(initSessionCurrentPurchaseId(null));
  }, [dispatch, location.search]);

  const messageHandler = useCallback(
    ({ data, origin }) => {
      if (!origin?.match(new RegExp(process.env.REACT_APP_RIOTPAY_HOST_REGEX))) {
        return;
      }

      switch (data.tag) {
        case "enable-action-btn":
          setState({ actionBtnDisabled: false, canStartPurchase: true });
          break;

        case "disable-action-btn":
          setState({ actionBtnDisabled: true });
          break;

        case "action-btn-text":
          setState({ actionBtnText: data.text });
          break;

        case "enable-action-checkbox":
          setState({ actionCheckboxVisible: true, actionCheckboxChecked: data.checked });
          break;

        case "disable-action-checkbox":
          setState({ actionCheckboxVisible: false });
          break;

        case "enable-action-img":
          setState({ actionImgVisible: true });
          break;

        case "diable-action-img":
          setState({ actionImgVisible: false });
          break;

        case "action-checkbox-text":
          setState({ actionCheckboxText: data.text });
          break;

        case "action-disclaimer-text":
          setState({ actionDisclaimerText: data.text });
          break;

        default:
      }
    },
    [setState]
  );

  const fetchRiotPayData = () => {
    if (!shouldRedirectToDCON(failRedirectUrl)) {
      dispatch(loadPaymentOptions());
    }
  };

  useEffect(() => {
    if (
      paymentOptionsLoadingState === PAYMENT_OPTIONS_LOADED &&
      purchaseContextLoadingState === PURCHASE_CONTEXT_UNATTEMPTED
    ) {
      dispatch(loadPurchaseContext());
    }
  }, [dispatch, paymentOptionsLoadingState, purchaseContextLoadingState]);

  const nextStep = () => {
    if (nextStepRef.current) {
      nextStepRef.current();
      return;
    }

    const checkoutIframe = document.getElementById("checkout-start-container");
    if (checkoutIframe) {
      checkoutIframe.contentWindow.postMessage({ tag: "next-step" }, "*");
    }

    sendAnalytics("webpay-purchase_cc_submit", {
      psp: state.selectedPaymentOption?.paymentMethodId,
      puuid
    });
  };

  const selectPricePoint = (selectedPricePoint) => {
    dispatch(setPricePoint(selectedPricePoint));
    setState({
      actionBtnDisabled: false
    });
    sendAnalytics("webpay-pricepoint_clicked", {
      price_point_amount: vcAmount,
      puuid
    });
  };

  useEffect(() => {
    if (isPricePoints && pricePointId && history.action === "POP" && state.actionBtnDisabled) {
      setState({
        actionBtnDisabled: false
      });
    }
  }, [history.action, isPricePoints, setState, state.actionBtnDisabled, pricePointId]);

  const selectPaymentOption = (selectedPaymentOption) => {
    const canStartPurchase = !!pricePointId && !!selectedPaymentOption?.paymentMethodId;
    setState({
      selectedPaymentOption,
      canStartPurchase,
      actionBtnDisabled: !canStartPurchase
    });

    sendAnalytics("webpay-psp_selected", {
      psp: selectedPaymentOption?.paymentMethodId,
      puuid
    });
  };

  const startPaymentOption = () => {
    history.push({
      pathname: "/payment-options"
    });
  };

  const startPurchase = () => {
    if (state.canStartPurchase && state.selectedPaymentOption?.paymentMethodId && pricePointId) {
      dispatch(
        confirmPricePointPurchase(
          state.selectedPaymentOption.paymentMethodId,
          pricePointId,
          state.selectedPaymentOption?.token
        )
      );
    }

    sendAnalytics("webpay-purchase_start", {
      psp: state.selectedPaymentOption?.paymentMethodId,
      puuid
    });
    sendAnalytics("webpay-purchase_item_price", {
      total_cost: vcPrice,
      puuid
    });
    sendAnalytics("webpay-purchase_amount_needed", {
      total_cost: vcNeeded,
      puuid
    });
  };

  const toggleActionCheckbox = () => {
    const actionCheckboxNewValue = !state.actionCheckboxChecked;
    setState({ actionCheckboxChecked: actionCheckboxNewValue });
    const checkoutIframe = document.getElementById("checkout-start-container");
    if (checkoutIframe) {
      checkoutIframe.contentWindow.postMessage({ tag: "toggle-action-checkbox", value: actionCheckboxNewValue }, "*");
    }
  };

  const getSupportUrl = () => {
    const supportUrlId = `support_link_${storeCode}`;
    let supportUrl = intl.formatMessage({ id: supportUrlId });

    // intl.formatMessage defaults to `id` if not found in translations
    if (supportUrl === supportUrlId) {
      supportUrl = intl.formatMessage({ id: "support_link" });
    }

    return supportUrl;
  };

  const getJpRefundPolicyUrl = () => {
    const urlId = `jp_refund_policy_url_${vcCode}`.toLowerCase();
    let url = intl.formatMessage({ id: urlId });

    // fall back to default url if not found
    if (url === urlId) {
      url = intl.formatMessage({ id: "jp_refund_policy_url_rp" });
    }

    return url;
  };

  useEffect(() => {
    if (!puuid) return;

    sendAnalytics("webpay-loaded", {
      puuid
    });
  }, [puuid]);

  useEffect(() => {
    if (isPricePoints && pricePoints && !isNaN(vcNeeded)) {
      pricePoints.every((pp) => {
        if (!(pp.virtualAmount + pp.virtualBonusAmount < vcNeeded)) {
          sendAnalytics("webpay-lowest_price_point_offered", {
            price_point_virtual_amount: pp.virtualAmount + pp.virtualBonusAmount,
            puuid
          });
          return false;
        }
        return true;
      });
    }
  }, [isPricePoints, pricePoints, puuid, vcNeeded]);

  useEffect(() => {
    window.addEventListener("message", messageHandler, false);

    consumeQueryParams();

    // We detect the user scroll event and add a class to body for changing RiotBar's background color
    // Will work with riotpay-themes/src/webpay/webpay-client.scss && body:not(.scrolled) #riotbar-bar {
    let ticking = false;
    const element = window.document.body;
    window.addEventListener(
      "scroll",
      () => {
        if (!ticking) {
          ticking = true;
          window.requestAnimationFrame(() => {
            element.classList.toggle("scrolled", window.scrollY > 80);
            ticking = false;
          });
        }
      },
      { passive: true }
    );

    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, [consumeQueryParams, messageHandler]);

  useEffect(() => {
    if (!!inGameName) {
      // Don't show accountTag if falsey
      const name = [inGameName, accountTag].filter((e) => !!e).join("#");
      window.RiotBar?.account.setAuthState({
        isAuthenticated: true,
        name
      });
    }
  }, [inGameName, accountTag, state.riotBarReady]);

  useEffect(() => {
    if (checkoutUrl && state.selectedPaymentOption !== {} && !purchaseHasStartedRef.current) {
      purchaseHasStartedRef.current = true;

      history.push({
        pathname: "/purchase",
        state: {
          checkoutUrl,
          selectedPaymentOption: state.selectedPaymentOption
        }
      });
    }
  }, [checkoutUrl, history, location.pathname, state.selectedPaymentOption]);

  useEffect(() => {
    if (
      isPaymentOptions &&
      state !== defaultState &&
      (location.state?.clearState || history.action === "POP") &&
      purchaseHasStartedRef.current
    ) {
      purchaseHasStartedRef.current = false;
      dispatch(clearCheckoutUrl());
      setState(defaultState);
    }
  }, [
    confirmPurchaseLoadingState,
    defaultState,
    dispatch,
    history,
    isPaymentOptions,
    location.state?.clearState,
    setState,
    state
  ]);

  const dataIsLoaded =
    paymentOptionsLoadingState === PAYMENT_OPTIONS_LOADED && purchaseContextLoadingState === PURCHASE_CONTEXT_LOADED;

  // EU checkbox must be checked before enabling the purchase button
  const actionButtonDisabledWithEu = showEuRightToCancel
    ? (isPaymentOptions && !euCheckboxChecked) || state.actionBtnDisabled
    : state.actionBtnDisabled;

  const shouldButtonBeDisabled = () => {
    if (isPurchasePage) {
      return state.actionBtnDisabled;
    }
    return actionButtonDisabledWithEu;
  };

  const confirmButtonOnClickWithAnalytics = () => {
    sendAnalytics("webpay-psp_confirm", {
      psp: state.selectedPaymentOption?.paymentMethodId,
      puuid
    });

    if (isPricePoints) {
      sendAnalytics("webpay-price_point_selected", {
        price_point_amount: vcAmount,
        puuid
      });
      startPaymentOption();
      // TODO: embedded PSP forms should re-enable the action btn when ready...
      setState({
        selectedPaymentOption: {},
        actionBtnDisabled: true
      });
    } else if (isPaymentOptions) {
      startPurchase();
    } else {
      nextStep();
    }
  };

  const linkOnClick = (analyticsEvent) => {
    return () => {
      sendAnalytics(analyticsEvent, {
        puuid
      });
    };
  };

  return (
    <div className={clsx("web-pay-container", storeCode, location.pathname === "/purchase" && "web-pay-checkout-flow")}>
      <RiotBar
        product="directcontent-lol"
        environment={process.env.REACT_APP_RIOTBAR_ENV}
        locale={intl.locale.replace("-", "_")}
        onLoad={fetchRiotPayData}
      />
      <BackButton
        onClick={() => {
          history.goBack();
        }}
      />
      <div className="web-pay-container-wrap">
        <div className="web-pay-container-group">
          <div className="web-pay-container-group-inner">
            <span className="bg-desktop-clip" />
            {location.pathname === "/price-points" ? <CartAndBalance /> : <OrderSummary />}
            <Footer
              supportLabel={intl.formatMessage({ id: "support" })}
              supportUrl={getSupportUrl()}
              supportOnClick={linkOnClick("webpay-support_click")}
              legalLabel={intl.formatMessage({ id: "legal" })}
              locale={intl.locale}
              jpPaymentCheckLabel={intl.formatMessage({ id: "jp_payment_check" })}
              jpCommercialTransactionsLabel={intl.formatMessage({ id: "jp_commercial_transactions" })}
              jpRefundPolicyLabel={intl.formatMessage({ id: "jp_refund_policy" })}
              jpRefundPolicyUrl={getJpRefundPolicyUrl()}
            />
          </div>
        </div>
        <div className="web-pay-container-group">
          <div className="web-pay-container-group-inner">
            <Route
              exact
              path="/payment-options"
              render={(props) => (
                <PaymentOptions
                  {...props}
                  disabled={!dataIsLoaded}
                  loading={purchaseHasStartedRef.current}
                  selectedPaymentOption={state.selectedPaymentOption}
                  selectPaymentOptionListener={selectPaymentOption}
                />
              )}
            />
            <Route
              exact
              path="/price-points"
              render={(props) => (
                <PricePoints
                  {...props}
                  disabled={!dataIsLoaded}
                  loading={!dataIsLoaded}
                  selectPricePoint={selectPricePoint}
                />
              )}
            />
            <Route
              exact
              path="/purchase"
              render={(props) => (
                <StartPurchase
                  {...props}
                  startPurchaseListener={() => {
                    if (!purchaseHasStartedRef.current) {
                      purchaseHasStartedRef.current = true;
                    }
                  }}
                  setNextStep={setNextStepFunc}
                  setValidPurchase={setValidPurchase}
                />
              )}
            />
            {registerRedirectRoute(failRedirectUrl)}
            <Route exact path={["/", "/start", "/checkout-cancelled"]}>
              <Redirect to="/price-points" />
            </Route>
            <PurchaseActions storeCode={storeCode}>
              {state.actionCheckboxVisible && (
                <div className="checkbox-container save-payment-checkbox-container">
                  <input
                    id="save-payment-checkbox"
                    type="checkbox"
                    checked={state.actionCheckboxChecked}
                    onChange={toggleActionCheckbox}
                  />
                  <label htmlFor="save-payment-checkbox">
                    <div className="checkbox-box"></div>
                    <div className="checkbox-text">
                      <FormattedMessage
                        id="save_payment_method"
                        defaultMessage="Save this payment method for future use"
                      />
                    </div>
                  </label>
                </div>
              )}
              <div
                onMouseOver={() => (isPaymentOptions ? setActionBtnDisabledGuide(true) : () => void 0)}
                onMouseOut={() => setActionBtnDisabledGuide(false)}
              >
                <ConfirmPurchaseButton
                  btnText={state.actionBtnText}
                  disabled={shouldButtonBeDisabled()}
                  onClick={confirmButtonOnClickWithAnalytics}
                  showImage={state.actionImgVisible}
                  storeCode={storeCode}
                />
              </div>
              {state.actionDisclaimerText && (
                <span className={clsx("confirm-purchase-msg", actionButtonDisabledWithEu && "disabled")}>
                  {state.actionDisclaimerText}
                </span>
              )}

              {/* EU Players */}
              {showEuRightToCancel && isPaymentOptions && (
                <EuRightToCancel
                  euCheckboxChecked={euCheckboxChecked}
                  setEuCheckboxChecked={setEuCheckboxChecked}
                  actionBtnDisabledGuide={actionBtnDisabledGuide}
                />
              )}
              {/* non-EU Players */}
              {!showEuRightToCancel && isPaymentOptions && (
                <span className="confirm-purchase-msg">
                  <FormattedMessage
                    id="agree_to_terms_of_service"
                    defaultMessage="By clicking continue to payment, you agree to the {link} and Refund Policy. Applicable taxes calculated at checkout."
                    values={{
                      link: (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={intl.formatMessage({ id: "terms_of_service_link" })}
                        >
                          <FormattedMessage id="terms_of_service" defaultMessage="Terms of Service" />
                        </a>
                      )
                    }}
                  />
                </span>
              )}
              {!showEuRightToCancel && isPricePoints && (
                <span className="confirm-purchase-msg">
                  <FormattedMessage
                    id="seeing_this_page_in_error"
                    defaultMessage="If you think that you’re seeing this message in error, please contact {support} for help."
                    values={{
                      support: (
                        <a target="_blank" rel="noopener noreferrer" href={getSupportUrl()}>
                          <FormattedMessage id="support" defaultMessage="Support" />
                        </a>
                      )
                    }}
                  />
                </span>
              )}
            </PurchaseActions>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WebPayPurchase;
