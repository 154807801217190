import { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { resolveLocale } from "@riotgames/riotpay-l18n";
import {
  getError,
  getErrorReason,
  getHumanReadableErrorReason,
  resetErrorMessage
} from "@riotgames/lib-riotpay-state/modules/error";
import { getStorefront, getSelectedPaymentMethodId } from "@riotgames/lib-riotpay-state/modules/paymentOptions";
import { getFailRedirectUrl, getPuuid } from "@riotgames/lib-riotpay-state/modules/purchaseContext";
import { captureExceptionWithTags, showReportDialog } from "@riotgames/riotpay-utils";
import { sendAnalytics } from "../utils/analytics";

import CreditCardErrorMessage from "../components/CreditCardErrorMessage";
import GenericErrorMessage from "../components/GenericErrorMessage";

const RIOTPAY_ERROR_KEY = "riotpayError";

class ErrorBoundary extends Component {
  state = {
    error: null,
    errorInfo: null
  };

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });

    const { selectedPaymentMethodId, storefrontAccountCode } = this.props;

    captureExceptionWithTags(error, errorInfo, {
      locale: resolveLocale(),
      storefront: storefrontAccountCode,
      paymentMethodId: selectedPaymentMethodId
    });
  }

  componentDidUpdate(prevProps) {
    if (!!this.props.failRedirectUrl) {
      sessionStorage.setItem("failRedirectUrl", this.props.failRedirectUrl);
    }

    if (this.props.error && prevProps.error !== this.props.error) {
      sendAnalytics("webpay-error", {
        errorCode: this.props.errorReason,
        errorReason: this.props.error,
        puuid: this.props.puuid
      });
    }
  }

  appendRiotpayError(url, errorCode) {
    const urlObj = new URL(url);
    urlObj.searchParams.set(RIOTPAY_ERROR_KEY, getHumanReadableErrorReason(errorCode));
    return urlObj.toString();
  }

  onAckError() {
    const { intl } = this.props;

    showReportDialog({
      title: intl.formatMessage({
        id: "sentry_user_feedback_title",
        defaultMessage: "It looks like we’re having issues."
      }),
      subtitle: intl.formatMessage({
        id: "sentry_user_feedback_subtitle",
        defaultMessage: "Our team has been notified."
      }),
      subtitle2: intl.formatMessage({
        id: "sentry_user_feedback_subtitle2",
        defaultMessage: "If you’d like to help, tell us what happened below."
      }),
      labelName: intl.formatMessage({
        id: "sentry_user_feedback_labelName",
        defaultMessage: "Name"
      }),
      labelEmail: intl.formatMessage({
        id: "sentry_user_feedback_labelEmail",
        defaultMessage: "Email"
      }),
      labelComments: intl.formatMessage({
        id: "sentry_user_feedback_labelComments",
        defaultMessage: "What happened?"
      }),
      labelClose: intl.formatMessage({
        id: "sentry_user_feedback_labelClose",
        defaultMessage: "Close"
      }),
      labelSubmit: intl.formatMessage({
        id: "sentry_user_feedback_labelSubmit",
        defaultMessage: "Submit"
      }),
      errorGeneric: intl.formatMessage({
        id: "sentry_user_feedback_errorGeneric",
        defaultMessage: "An unknown error occurred. Please try again."
      }),
      errorFormEntry: intl.formatMessage({
        id: "sentry_user_feedback_errorFormEntry",
        defaultMessage: "The highlighted fields are invalid - please try again."
      }),
      successMessage: intl.formatMessage({
        id: "sentry_user_feedback_successMessage",
        defaultMessage: "Thank you for the feedback - it has been sent."
      })
    });
  }

  render() {
    if (`${this.props.errorReason}`.startsWith("cc-")) {
      return (
        <CreditCardErrorMessage
          errorCode={this.props.errorReason}
          onAck={() => {
            this.props.resetErrorMessage();
            this.props.history.push("/payment-options");
          }}
        />
      );
    }

    if (this.props.error || this.state.error) {
      const failRedirectUrl = this.props.failRedirectUrl || sessionStorage.getItem("failRedirectUrl");
      if (failRedirectUrl) {
        window.location.href = this.appendRiotpayError(failRedirectUrl, this.props.errorReason);
        return this.props.children;
      }

      return (
        <GenericErrorMessage
          errorCode={this.props.errorReason || this.state.errorInfo}
          onAck={() => {
            this.props.resetErrorMessage();
            window.location.href = "/price-points";
            window.location.reload();
          }}
        />
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  error: PropTypes.any,
  errorReason: PropTypes.any,
  failRedirectUrl: PropTypes.string,
  puuid: PropTypes.string,
  selectedPaymentMethodId: PropTypes.string,
  storefrontAccountCode: PropTypes.string
};

const mapStateToProps = (state) => ({
  error: getError(state),
  errorReason: getErrorReason(state),
  failRedirectUrl: getFailRedirectUrl(state),
  puuid: getPuuid(state),
  selectedPaymentMethodId: getSelectedPaymentMethodId(state),
  storefrontAccountCode: getStorefront(state)
});

export default injectIntl(withRouter(connect(mapStateToProps, { resetErrorMessage })(ErrorBoundary)));
