import { useDispatch } from "react-redux";
import { useHistory, useLocation, Redirect } from "react-router-dom";
import { setError } from "@riotgames/lib-riotpay-state/modules/error";
import { usePSPComponent } from "@riotgames/riotpay-sdk";
import { FormattedMessage } from "react-intl";

import EmbeddedPurchase from "./EmbeddedPurchase";

const PURCHASE_TITLE = "Start Purchase";

function StartPurchase({ startPurchaseListener, setNextStep, setValidPurchase }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const checkoutUrl = location.state?.checkoutUrl;
  const selectedPaymentOption = location.state?.selectedPaymentOption;

  const {
    PSPComponent,
    componentProps,
    error: pspComponentError
  } = usePSPComponent(selectedPaymentOption.paymentMethodId);

  const riotpaySdkEventHandler = (paymentMethodId, eventName, eventData) => {
    if (eventName === "error") {
      return dispatch(setError(eventData));
    }

    if (eventData.redirectUrl) {
      window.location.href = eventData.redirectUrl;
      return;
    }

    // Check for valid Adyen form
    if (paymentMethodId.toLowerCase() === "adyen") {
      setValidPurchase(eventData, eventName);
    }
  };

  document.title = PURCHASE_TITLE;

  if (history.action === "POP" && location.pathname === "/purchase") {
    return (
      <Redirect
        to={{
          pathname: "/payment-options",
          state: { clearState: true }
        }}
      />
    );
  }

  startPurchaseListener();

  if (pspComponentError) {
    return dispatch(setError(pspComponentError));
  }

  if (process.env.REACT_APP_RIOTPAY_SDK_ENABLED === "true" && PSPComponent) {
    return (
      <div className="start-purchase">
        <div className="start-purchase-group-title">
          <h2>
            <FormattedMessage id="checkout" defaultMessage="Checkout" />
          </h2>
        </div>
        <div className="start-purchase-group">
          <PSPComponent
            {...componentProps}
            checkoutUrl={checkoutUrl}
            eventHandler={riotpaySdkEventHandler}
            setNextStep={setNextStep}
          />
        </div>
      </div>
    );
  } else if (selectedPaymentOption.viewTypes?.includes("EMBEDDED")) {
    const url = new URL(checkoutUrl);
    url.searchParams.set("viewType", "EMBEDDED");
    return <EmbeddedPurchase checkoutUrl={url.toString()} />;
  }

  window.location.href = checkoutUrl;

  return (
    <div className="pay-options loading">
      <div className="loading-spinner" />
    </div>
  );
}

export default StartPurchase;
