import { FormattedMessage } from "react-intl";

function CreditCardErrorMessage({ errorCode = "cc-generic", onAck }) {
  return (
    <div className="error-page">
      <div className="error-box">
        <h2>
          <FormattedMessage id="transaction_declined" />
        </h2>
        <span className="error-text">
          <FormattedMessage id={errorCode} />
        </span>
        <button className="btn-primary" onClick={onAck}>
          <FormattedMessage id="button_return_checkout" />
        </button>
      </div>
    </div>
  );
}

export default CreditCardErrorMessage;
