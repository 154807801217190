/* eslint-disable default-param-last */
import { CALL_API } from "../middleware/api";
import { getSessionUseApiV2 } from "./session";

export const LOAD_PURCHASE_CONTEXT = "pmc-client/purchase-context/LOAD_PURCHASE_CONTEXT";
export const LOAD_PURCHASE_CONTEXT_SUCCESS = "pmc-client/purchase-context/LOAD_PURCHASE_CONTEXT_SUCCESS";
export const LOAD_PURCHASE_CONTEXT_FAILED = "pmc-client/purchase-context/LOAD_PURCHASE_CONTEXT_FAILED";

export const viewTypeEnum = (Object.freeze || Object)({
  REDIRECT: "REDIRECT",
  EMBEDDED: "EMBEDDED"
});

export const loadPurchaseContextIfNeeded = () => {
  return (dispatch, getState) => {
    const state = getState();
    if (isContextLoaded(state)) {
      return;
    }

    if (getSessionUseApiV2(state)) {
      return dispatch(loadPurchaseContextV2());
    }

    return dispatch(loadPurchaseContext());
  };
};

export const loadPurchaseContext = () => ({
  [CALL_API]: {
    types: [LOAD_PURCHASE_CONTEXT, LOAD_PURCHASE_CONTEXT_SUCCESS, LOAD_PURCHASE_CONTEXT_FAILED],
    endpoint: "/riotpay/pmc/v1/purchase-context"
  }
});

export const loadPurchaseContextV2 = () => ({
  [CALL_API]: {
    types: [LOAD_PURCHASE_CONTEXT, LOAD_PURCHASE_CONTEXT_SUCCESS, LOAD_PURCHASE_CONTEXT_FAILED],
    endpoint: "/riotpay/pmc/v2/purchase-context"
  }
});

export const LOADING_STATE_UNATTEMPTED = "UNATTEMPTED";
export const LOADING_STATE_LOADING = "LOADING";
export const LOADING_STATE_SUCCESS = "SUCCESS";
export const LOADING_STATE_FAILED = "FAILED";

export const initialState = {
  loaded: false,
  loading: false,
  loadingState: LOADING_STATE_UNATTEMPTED,
  context: {}
};

const SET_PRICE_POINT = "SET_PRICE_POINT";

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_PURCHASE_CONTEXT: {
      return {
        ...state,
        loading: true,
        loaded: false,
        loadingState: LOADING_STATE_LOADING
      };
    }
    case LOAD_PURCHASE_CONTEXT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        loadingState: LOADING_STATE_SUCCESS,
        context: action.response
      };
    }
    case LOAD_PURCHASE_CONTEXT_FAILED: {
      return {
        ...state,
        loadingState: LOADING_STATE_FAILED
      };
    }
    case SET_PRICE_POINT: {
      return {
        ...state,
        context: {
          ...state.context,
          pricePointId: action.pricePoint.id,
          realCurrencyAmount: action.pricePoint.realAmountCents / 100,
          realCurrencyCode: action.pricePoint.realCurrencyCode,
          virtualCurrencyAmount: action.pricePoint.virtualAmount + action.pricePoint.virtualBonusAmount,
          virtualCurrencyCode: action.pricePoint.virtualCurrencyCode
        }
      };
    }
    default:
      return state;
  }
}

export const getPurchaseContext = (state) => state.purchaseContext.context;
export const getLoadingState = (state) => state.purchaseContext.loadingState;
export const isContextLoaded = (state) => state.purchaseContext.loaded;

export const getAccountTag = (state) => getPurchaseContext(state).tag;
export const getInGameName = (state) => getPurchaseContext(state).inGameName || getPurchaseContext(state).summonerName;
export const getPuuid = (state) => getPurchaseContext(state).puuid;
export const getRealCurrencyAmount = (state) => getPurchaseContext(state).realCurrencyAmount;
export const getRealCurrencyCode = (state) => getPurchaseContext(state).realCurrencyCode;
export const getSalesTax = (state) => getPurchaseContext(state).salesTax;
export const getVirtualCurrencyAmount = (state) => getPurchaseContext(state).virtualCurrencyAmount;
export const getVirtualCurrencyAmountPrice = (state) => getPurchaseContext(state).virtualCurrencyAmountPrice;
export const getVirtualCurrencyAmountDiscount = (state) => getPurchaseContext(state).virtualCurrencyAmountDiscount;
export const getVirtualCurrencyWalletBalance = (state) =>
  getPurchaseContext(state).virtualCurrencyWalletBalance;
export const getVirtualCurrencyCode = (state) => getPurchaseContext(state).virtualCurrencyCode;
export const getPricePointId = (state) => getPurchaseContext(state).pricePointId;
export const getFailRedirectUrl = (state) => getPurchaseContext(state).failRedirectUrl;
export const getOrderDetails = (state) => {
  try {
    return JSON.parse(getPurchaseContext(state).orderDetailJson);
  } catch (err) {
    return {};
  }
};

export function setPricePoint(pricePoint) {
  return {
    type: SET_PRICE_POINT,
    pricePoint
  };
}
