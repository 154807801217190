import { FormattedMessage } from "react-intl";

import { errorReasonEnum } from "@riotgames/lib-riotpay-state/modules/error";

function GenericErrorMessage({ error = "error", errorCode, onAck }) {
  const getErrorReasonFromCode = (code = "payment_error_text") => {
    if (code === errorReasonEnum.AUTH_ERROR) {
      return "payment_expired_text";
    }

    return code;
  };

  return (
    <div className="error-page">
      <div className="error-box">
        <h1>
          <FormattedMessage id={error} defaultMessage="Error" />
        </h1>
        <span className="error-text">
          <FormattedMessage
            id={getErrorReasonFromCode(errorCode)}
            defaultMessage="You have encountered an error. Please try again later."
            values={{
              link: <FormattedMessage id="support_error_link" defaultMessage="" />
            }}
          />
        </span>
        <span className="error-footer">
          {errorCode !== errorReasonEnum.AUTH_ERROR && (
            <button className="btn-primary" onClick={onAck}>
              <FormattedMessage id="button_back" defaultMessage="Back" />
            </button>
          )}
        </span>
      </div>
    </div>
  );
}

export default GenericErrorMessage;
