import { IntlProvider } from "react-intl";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter as Router, Switch } from "react-router-dom";

import App from "./App";
import ErrorBoundary from "./ErrorBoundary";
import WebPayPurchase from "./WebPayPurchase";

function Root({ locale, messages, store }) {
  return (
    <IntlProvider locale={locale} messages={messages}>
      <ReduxProvider store={store}>
        <Router>
          <ErrorBoundary>
            <App>
              <Switch>
                <WebPayPurchase />
              </Switch>
            </App>
          </ErrorBoundary>
        </Router>
      </ReduxProvider>
    </IntlProvider>
  );
}

export default Root;
