import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";

import { buildReducers, defaultReducers } from "./modules/reducers";
import apiMiddleware from "./middleware/api";
import thunkMiddleware from "redux-thunk";

export default (initialState, client, include = defaultReducers) => {
  const enhancer = composeWithDevTools(applyMiddleware(thunkMiddleware, apiMiddleware(client)));

  return createStore(buildReducers(include), initialState, enhancer);
};
