import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useIntl } from "react-intl";
import { iconResolver } from "@riotgames/riotpay-utils";

import { PaymentOptionCardProps } from "./PaymentOptionCard";

import iconMap from "../iconMap.json";

enum SavedPaymentMethodType {
  GENERAL,
  CREDIT_CARD
}

interface SavedPaymentMethodCardProps extends PaymentOptionCardProps {
  last4Digits?: string;
  cardBrand?: string;
}

const SavedPaymentMethodCard: React.FC<SavedPaymentMethodCardProps> = ({
  paymentMethodId,
  disabled,
  onClick,
  selected,
  cardBrand,
  last4Digits
}) => {
  const intl = useIntl();

  const [type, setType] = useState(SavedPaymentMethodType.GENERAL);

  useEffect(() => {
    if (cardBrand && last4Digits && type !== SavedPaymentMethodType.CREDIT_CARD) {
      setType(SavedPaymentMethodType.CREDIT_CARD);
    }
  }, [cardBrand, last4Digits, type]);

  const mainMessage =
    type === SavedPaymentMethodType.CREDIT_CARD
      ? intl.formatMessage(
          {
            id: "credit_card_ending_in"
          },
          {
            cardBrand: intl.formatMessage({
              id: `credit_card_brand_${cardBrand?.toLowerCase()}`,
              defaultMessage: cardBrand
            }),
            last4Digits
          }
        )
      : intl.formatMessage({
          id: `psp-name-${paymentMethodId}`,
          defaultMessage: paymentMethodId
        });

  return (
    <div
      className={clsx("pay-option-card", selected && !disabled && "active", disabled && "disabled")}
      data-interaction-id={`webpay-psp_${paymentMethodId}`}
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
    >
      <img
        className="pay-option-img"
        src={iconResolver(iconMap, { paymentMethod: paymentMethodId, cardBrand: cardBrand?.toLowerCase() })}
        alt={paymentMethodId}
      />
      <label>{mainMessage}</label>
    </div>
  );
};

export default SavedPaymentMethodCard;
