import "core-js"; // polyfill

import { StrictMode } from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import queryString from "query-string";
import { createStore } from "@riotgames/lib-riotpay-state";
import { initialState as paymentOptionsInitialState } from "@riotgames/lib-riotpay-state/modules/paymentOptions";
import { initialState as sessionInitialState } from "@riotgames/lib-riotpay-state/modules/session";
import { loadTranslations, resolveLocale } from "@riotgames/riotpay-l18n";
import { ApiClient, applyGlobalCssTheme, initSentry } from "@riotgames/riotpay-utils";

import ContainerRoot from "./containers/Root";

const apiClient = new ApiClient(process.env.REACT_APP_PMC_EDGE);
const preloadedState = preloadedStateFromSession();

const locale = resolveLocale().replace("_", "-");
const translations = loadTranslations(locale);
document.documentElement.lang = locale.slice(0, 2); // set 'lang' attribute on html tag

const reducersToInclude = ["error", "paymentOptions", "purchaseContext", "session", "user"];
const store = createStore(preloadedState, apiClient, reducersToInclude);

initSentry(
  process.env.REACT_APP_SENTRY_DSN,
  process.env.REACT_APP_NAME,
  process.env.REACT_APP_ENV,
  process.env.REACT_APP_VERSION
);

if (process.env.REACT_APP_GTM_ID) {
  if (window.dataLayer === undefined) window.dataLayer = [];

  TagManager.initialize({
    dataLayer: window.dataLayer,
    gtmId: process.env.REACT_APP_GTM_ID
  });
}

const renderDom = () => {
  ReactDOM.render(
    <StrictMode>
      <ContainerRoot locale={locale} messages={translations} store={store} />
    </StrictMode>,
    document.getElementById("root")
  );
};

// Only render DOM after styles have been loaded
applyGlobalCssTheme(preloadedState.session.cssTheme, renderDom);

function preloadedStateFromSession() {
  const initialState = {
    session: {
      ...sessionInitialState,
      useApiV2: true
    },
    paymentOptions: {
      ...paymentOptionsInitialState
    }
  };

  const queryParams = queryString.parse(window.location.search);

  const sessionToken = queryParams.s || sessionStorage.getItem("sessionToken");
  if (sessionToken) {
    initialState.session.token = sessionToken;
  }

  const instance = queryParams.i || sessionStorage.getItem("instance");
  if (instance) {
    initialState.session.instance = instance;
  }

  const currentPurchaseId = sessionStorage.getItem("currentPurchaseId");
  // Need to check for 'null' since otherwise we will have a string 'null' stored in the session storage
  if (currentPurchaseId && currentPurchaseId !== "null") {
    initialState.session.currentPurchaseId = currentPurchaseId;
  }

  const cssTheme = queryParams.t || sessionStorage.getItem("cssTheme");
  if (cssTheme) {
    initialState.session.cssTheme = cssTheme;
  }

  const storeCode = queryParams.sc || sessionStorage.getItem("storeCode");
  if (storeCode) {
    initialState.session.storeCode = storeCode;
  }

  const virtualCurrencyCode = queryParams.vc || sessionStorage.getItem("virtualCurrencyCode");
  if (virtualCurrencyCode) {
    initialState.paymentOptions.currencyCode = virtualCurrencyCode;
  }

  return initialState;
}
