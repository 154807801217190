import { useSelector } from "react-redux";
import {
  getAllPricePoints,
  getDoubleBonusStatus,
  getPaymentOptionsLoadingState,
  getPurchaseCurrency,
  LOADING_STATE_FAILED as FAILED,
  LOADING_STATE_LOADING as LOADING
} from "@riotgames/lib-riotpay-state/modules/paymentOptions";
import PricePointCard from "../components/PricePointCard";
import {
  getPricePointId,
  getVirtualCurrencyAmountPrice,
  getVirtualCurrencyWalletBalance
} from "@riotgames/lib-riotpay-state/modules/purchaseContext";
import { Loading } from "@riotgames/riotpay-components";
import { FormattedMessage } from "react-intl";

function PricePoints({ disabled, loading, selectPricePoint }) {
  const vcTotalAmount = useSelector(getVirtualCurrencyAmountPrice);
  const vcBalance = useSelector(getVirtualCurrencyWalletBalance);
  const vcCode = useSelector(getPurchaseCurrency);
  const pricePoints = useSelector(getAllPricePoints);
  const hasDoubleBonusVc = useSelector(getDoubleBonusStatus);
  const selectedPricePointId = useSelector(getPricePointId);
  const loadingState = useSelector(getPaymentOptionsLoadingState);

  const vcNeeded = vcTotalAmount - (vcBalance || 0);

  if (loadingState === LOADING || loadingState === FAILED || loading) {
    return <Loading className="pay-options" />;
  }

  return (
    <div className="price-points">
      <div className="price-points-group">
        <div className="price-points-group-title">
          <h2>
            <FormattedMessage
              id="vc_purchase"
              defaultMessage="Purchase {code}"
              values={{
                code: <FormattedMessage id={`vc_code_${vcCode}`} defaultMessage={vcCode} />
              }}
            />
          </h2>
          <h3 className="subtitle">
            <FormattedMessage
              id="vc_insufficient_subtitle"
              defaultMessage="You do not have enough {code} to purchase item(s). Add {code} to continue."
              values={{
                code: <FormattedMessage id={`vc_code_${vcCode}`} defaultMessage={vcCode} />
              }}
            />
          </h3>
          {hasDoubleBonusVc && (
            <h5 className="double-bonus-title">
              <FormattedMessage id="doubleRP_promo" defaultMessage="2X BONUS RP UNTIL 07/31" />
            </h5>
          )}
        </div>
        <div className="order-items">
          {pricePoints.map((pp) => (
            <PricePointCard
              key={pp.id}
              realCurrency={pp.realCurrencyCode}
              realAmount={pp.realAmountCents}
              virtualCurrencyCode={pp.virtualCurrencyCode}
              virtualCurrencyName={pp.virtualCurrencyName}
              virtualCurrencyBaseAmount={pp.virtualAmount}
              virtualCurrencyBonusAmount={pp.virtualBonusAmount}
              disabled={disabled || pp.virtualAmount + pp.virtualBonusAmount < vcNeeded}
              onClick={() => selectPricePoint(pp)}
              selected={pp.id === selectedPricePointId}
              hasDoubleBonusVc={hasDoubleBonusVc}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default PricePoints;
