import React from "react";
import clsx from "clsx";
import { FormattedMessage, FormattedNumber } from "react-intl";

export interface PricePointCardProps {
  realCurrency: string;
  realAmount: number;
  virtualCurrencyCode: string;
  virtualCurrencyName: string;
  virtualCurrencyBaseAmount: number;
  virtualCurrencyBonusAmount: number;
  disabled: boolean;
  onClick: () => void;
  selected: boolean;
  hasDoubleBonusVc: boolean;
}

const PricePointCard: React.FC<PricePointCardProps> = ({
  realCurrency,
  realAmount,
  virtualCurrencyCode,
  virtualCurrencyBaseAmount,
  virtualCurrencyBonusAmount,
  disabled,
  onClick,
  selected,
  hasDoubleBonusVc
}) => {
  const currency = <FormattedNumber style={"currency"} value={realAmount / 100} currency={realCurrency} />;
  return (
    <div
      className={clsx(
        "price-point-card",
        selected && !disabled && "selected",
        disabled && "disabled",
        virtualCurrencyCode.toLowerCase()
      )}
      onClick={disabled ? () => null : onClick}
    >
      <span className="hovertext">
        {disabled ? (
          <FormattedMessage
            id="insufficient_vc_hover"
            defaultMessage="This option does not meet the {code} requirements for your purchase."
            values={{
              code: (
                <FormattedMessage
                  id={`vc_code_${virtualCurrencyCode.toLowerCase()}`}
                  defaultMessage={virtualCurrencyCode}
                />
              )
            }}
          />
        ) : (
          <FormattedMessage
            id="minimum_vc_hover"
            defaultMessage="Minimum {code} bundle needed to continue purchase."
            values={{
              code: (
                <FormattedMessage
                  id={`vc_code_${virtualCurrencyCode.toLowerCase()}`}
                  defaultMessage={virtualCurrencyCode}
                />
              )
            }}
          />
        )}
      </span>
      <div className="vc-container">
        <div className="vc">
          {<FormattedNumber value={virtualCurrencyBaseAmount} />}{" "}
          <FormattedMessage id={`vc_code_${virtualCurrencyCode.toLowerCase()}`} defaultMessage={virtualCurrencyCode} />
        </div>
        {virtualCurrencyBonusAmount > 0 &&
          (hasDoubleBonusVc ? (
            <>
              <div className="vcb text-primary">
                +{" "}
                <span style={{ textDecoration: "line-through" }}>
                  <FormattedNumber value={Math.round(virtualCurrencyBonusAmount / 2)} />{" "}
                </span>
                <FormattedNumber value={virtualCurrencyBonusAmount} />{" "}
                <FormattedMessage
                  id={`vc_bonus_${virtualCurrencyCode.toLowerCase()}`}
                  defaultMessage={`Bonus ${virtualCurrencyCode}`}
                />
              </div>
            </>
          ) : (
            <div className="vcb text-primary">
              + <FormattedNumber value={virtualCurrencyBonusAmount} />{" "}
              <FormattedMessage
                id={`vc_bonus_${virtualCurrencyCode.toLowerCase()}`}
                defaultMessage={`Bonus ${virtualCurrencyCode}`}
              />
            </div>
          ))}
      </div>
      <button className="btn fist-bump-sm">
        <span className="btn-title-price">{currency}</span>
        <span className="btn-secondary-currency"> {realCurrency}</span>
      </button>
    </div>
  );
};

export default PricePointCard;
